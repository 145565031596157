import { Drawer, ListItem, ListItemIcon, Popper } from '@mui/material';
import styled from '@styled';
import { APP_HEADER_HEIGHT, SIDEBAR_WIDTH } from 'lib/constants';
import { transparentize } from 'polished';
export const SpaceName = styled.p`
	text-align: center;
	text-transform: capitalize;
	font-weight: bold;
	margin: 0;
`;
export const SidebarButton = styled.div`
	width: 100%;
	line-height: 14px;
	color: white;
	font-weight: bold;
	display: grid;
	place-items: center;

	a {
		display: grid;
		place-items: center;
		text-decoration: none;
		color: white;
	}

	svg {
		height: 30px;
		width: 30px;
		margin-bottom: 8px;
	}
`;
export const LayoutContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;

	main {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		& > section {
			position: relative;
			width: 100%;
			height: 100%;
			padding-top: ${APP_HEADER_HEIGHT}px;
			overflow: auto;
		}
	}
`;
export const SidebarContainer = styled(Drawer)`
	width: ${SIDEBAR_WIDTH}px;
	text-transform: uppercase;
	font-size: 10px;
	.MuiDrawer-paperAnchorDockedLeft {
		border: none;
	}
	.MuiDrawer-paper {
		background-color: ${({
  theme
}) => theme.palette.primary.dark};
		width: ${SIDEBAR_WIDTH}px;
	}
	.MuiSvgIcon-root {
		margin: auto;
	}
	.MuiListItem-button {
		text-align: center;
	}
	.Mui-selected {
		color: white;
		background-color: #ffff;
	}
	.MuiAvatar-root {
		margin: auto;
	}
	.MuiList-root {
		padding: 0;
	}
	.MuiList-root:first-child {
		margin-bottom: 5px;
	}
	.MuiListItem-root {
		min-height: 80px;
	}
	.MuiListItem-root:first-child {
		min-height: 60px;
		padding: ${({
  theme
}) => theme.spacing(1, 0)};
		flex-direction: column;
		color: white;
	}

	.MuiListItem-root {
		border-top: 1px solid #ffffff1a;
	}

	@media (max-height: 700px) {
		.MuiListItem-root {
			min-height: 70px;
		}
	}

	@media (max-height: 655px) {
		font-size: 8px;

		.MuiListItem-root {
			min-height: 40px;
		}

		.MuiListItem-root:first-child {
			min-height: 50px;
		}
	}
`;
export const SidebarLogo = styled.img`
	width: 40px;
	height: 40px;
	margin: ${({
  theme
}) => theme.spacing(0.5, 'auto', 0.5, 'auto')};
`;
export const ModalItem = styled(ListItem)`
	color: ${props => props.theme.palette.primary.dark};

	svg {
		fill: ${props => props.theme.palette.primary.dark};
		height: 25px;
		width: 25px;
	}

	p {
		color: ${props => props.theme.palette.primary.dark};
	}
`;
export const WorkspaceIcon = styled(ListItemIcon)`
	& > svg {
		fill: ${({
  $isActive
}) => $isActive ? '#4CB07A' : '#DDDDDD'};
	}
`;
const SideBarPopper = styled(Popper)`
	${ModalItem}:not(:last-child) {
		border-bottom: 1px solid ${props => props.theme.palette.divider};
	}
`;
export const WorkspacesPopper = styled(SideBarPopper)`
	transform: translate3d(${SIDEBAR_WIDTH}px, ${APP_HEADER_HEIGHT}px, 0px) !important;

	.MuiList-root {
		max-height: 80vh;
		overflow: auto;
		scrollbar-gutter: stable;
		overscroll-behavior-y: contain;
	}
`;
export const AccountPopper = styled(SideBarPopper)`
	box-shadow: 0 4px 17px 6px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	.MuiAvatar-img {
		object-fit: contain;
	}
`;
export const SubscriptionWarningContainer = styled.div`
	height: ${APP_HEADER_HEIGHT}px;
	padding: ${({
  theme
}) => theme.spacing(0, 2)};
	background-color: #f7e18e;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
`;
export const StyledListItem = styled(ListItem)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	font-weight: bold;
	text-align: center;
	background-color: ${({
  $isSelected,
  theme
}) => $isSelected && theme.palette.primary.main};
	position: relative;
	cursor: ${({
  $disabled
}) => $disabled ? 'not-allowed' : 'pointer'};

	&:hover {
		background-color: ${({
  theme,
  $disabled
}) => !$disabled && transparentize(0.25, theme.palette.primary.main)};
	}

	svg {
		height: 25px;
		width: 25px;
		margin-bottom: ${({
  theme
}) => theme.spacing(1)};
	}

	@media (max-height: 690px) {
		svg {
			height: 20px;
			width: 20px;
		}

		.MuiChip-root {
			height: 16px;
		}
		.MuiChip-label {
			font-size: 8px;
		}
	}

	@media (max-height: 600px) {
		svg {
			height: 16px;
			width: 16px;
		}

		.MuiChip-root {
			height: 14px;
		}
		.MuiChip-label {
			font-size: 8px;
		}
	}
`;